import { ADMIN, HOME, LOGIN } from "../Constants/routePathConstants";
import Admin from "../Pages/Admin/admin";
import Home from "../Pages/Home/home";
import Login from "Pages/Login/Login";

export const AppRoutes = [
  { path: HOME, component: <Home /> },
  { path: ADMIN, component: <Admin /> },
  { path: LOGIN, component: <Login /> },
  { path: "*", component: "404 Not Found" },
];
