import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useForm } from "react-hook-form";
import { LoginStyle } from "./styles";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Image from "../../Images/logo-post.png";
import { InputLabel } from "@mui/material";

const jump = keyframes`
  from{
    transform: translateY(0)
  }
  to{
    transform: translateY(-3px)
  }
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Form = styled.form`
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  font-size: 16px;
  outline: none;
  background: #fff;
  color: #535353;
  max-height: 40px;
  height: 45px;
  padding: 2px 10px;
  border-radius: 3px;
  border: 1px solid #efefef;
  margin-bottom: 20px;
  transition: 0.3s ease all;
  :focus,
  :hover {
    border: 1px solid #2e6fe4;
  }
`;

const Button = styled.button`
  max-width: 100%;
  height: 45px;
  padding: 5px 10px;
  color: rgb(253, 249, 243);
  font-weight: 600;
  text-transform: uppercase;
  background: #061534;
  border: none;
  border-radius: 3px;
  outline: 0;
  cursor: pointer;
  margin-top: 0.6rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
  :hover {
    background: #4688f5;
    animation: ${jump} 0.2s ease-out forwards;
  }
`;

const Title = styled.h2`
  font-weight: normal;
  color: #2a2a29;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600; 
`;

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const loginData = {
    email: "admin",
    password: "Post2023!",
  };

  useEffect(() => {
    if (localStorage.getItem("isAuthenticated") === "true") {
      navigate("/");
    }
  }, [navigate]);

  const onSubmit = (data) => {
    if (JSON.stringify(data) === JSON.stringify(loginData)) {
      localStorage.setItem("isAuthenticated", "true");
      navigate("/admin");
    } else {
      const formError = {
        type: "server",
        message: "Username or Password Incorrect",
      };
      setError("email", formError);
      setError("password", formError);
    }
  };

  return (
    <LoginStyle>
      <Box className="Login_form">
        <img src={Image} alt="No-data" />
        <Title>Login</Title>
        <Wrapper>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputLabel sx={{ marginBottom: "5px", color: "#000" }}>
              User Name
            </InputLabel>
            <Input
              type="text"
              name="email"
              {...register("email", {
                //  pattern: /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/,
                required: true,
              })}
            />
            {errors.email ? (
              <>
                {errors.email?.type === "required" && (
                  <p className="errormsg">This field is required</p>
                )}
                {errors.email?.type === "server" && (
                  <p className="errormsg">{errors.email?.message}</p>
                )}
                {/* {errors.email.type === "pattern" && (
                <p className="errormsg">Please Enter a valid Email</p>
              )} */}
              </>
            ) : null}
            <InputLabel sx={{ marginBottom: "15px", color: "#000" }}>
              Password
            </InputLabel>
            <Input
              type="password"
              name="password"
              {...register("password", { required: true })}
            />
            {errors.password?.type === "required" && (
              <p className="errormsg">This field is required</p>
            )}
            {errors.password?.type === "server" && (
              <p className="errormsg">{errors.password?.message}</p>
            )}
            <Button>Login</Button>
          </Form>
        </Wrapper>
      </Box>
    </LoginStyle>
  );
};

export default Login;
