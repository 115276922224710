import styled from "styled-components";

export const AdminStyle = styled.div`
  padding: 30px;
  background-color: #fafafa;
  height: 100vh;
  .upload_table {
    width: 100%;
    height: calc(100vh - 100px);
    h1 {
      font-size: 26px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 10px;
      @media (max-width: 767px){
        font-size: 20px;
      }
    }
    & > div {
      padding: 30px;
      background: #fff;
      border-radius: 5px;
      border: none;
    }
    .MuiDataGrid-columnHeaders {
      background: linear-gradient(
        85deg,
        rgba(46, 88, 128, 1) 0%,
        rgba(184, 228, 255, 1) 100%
      );
      border-radius: 10px 10px 0 0;

      .MuiDataGrid-columnHeader {
        padding: 0 15px;
        &:focus {
          outline: none;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
      .MuiDataGrid-columnHeaderTitleContainer {
        font-family: var(--common-font);
        color: #fff;
        &:last-child {
          //    justify-content: flex-end;
        }
      }
    }
    .MuiDataGrid-footerContainer {
      border: none;
    }
    .MuiDataGrid-cell {
      border: none;
      padding: 0 15px;

      &:focus {
        outline: none;
      }
      &::-webkit-scrollbar {
        width: 1em;
        height: 3px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
        cursor: pointer;
      }
      .image_box {
        border: 1px solid #ddd;
        flex: 100%;
        margin: 0 2px;
      }
      img {
        object-fit: cover;
      }
    }
    .MuiDataGrid-cell:nth-child(4) {
      margin-right: 10px;
      overflow-x: auto !important;
      /* width: 330px !important;
      min-width: 330px !important; */
    }
    .MuiDataGrid-row {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      &:nth-child(2n) {
        background-color: #fafafa;
        margin: 15px 0;
      }
    }
    .MuiDataGrid-selectedRowCount {
      border: 2px solid hsl(0 0% 90%);
      padding: 10px 20px;
      border-radius: 5px;
      text-transform: capitalize;
      margin: 20px 0 0 0;
    }
  }

  .action_box {
    display: flex;
    gap: 10px;
    button {
      background: #325c83;
      color: #fff;
      font-weight: 600;
      font-family: var(--common-font);
      font-size: 14px;
    }
    .delete {
      background: #f00;
    }
  }
  button.MuiDataGrid-menuIconButton svg {
    color: #fff;
  }
  .MuiDataGrid-menuList li {
    font-family: var(--common-font);
  }
`;

export const DetailStyle = styled.div`
  overflow-y: inherit;

  h2 {
    position: relative;
    font-weight: 700;
    font-family: var(--common-font);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-size: 30px; */
    svg.MuiSvgIcon-root {
      cursor: pointer;
    }
  }
  .content-flex {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .description.MuiBox-root {
    width: 100%;
    flex: 100%;
  }

  .description.MuiBox-root p {
    word-break: break-all;
    height: auto; 
    max-height: 180px;
    overflow-y: auto;
  }

  .content-flex .MuiBox-root:not(.description.MuiBox-root) {
    max-width: calc(100% / 2 - 10px);
    flex: calc(100% / 2 - 10px);
  }

  .data-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    max-height: 350px;
    overflow-y: auto;
    @media (max-width:767px){
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width:567px){
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .img_holder {
    border: 1px solid #f3f3f3;
    img {
      width: 100%;
      max-width: 100%;
      height: 100px;
      object-fit: cover;
      aspect-ratio: 1/1;
      margin-bottom: -5px;
    }
  }
  .d-flex h4 {
    margin-bottom: 10px;
    text-transform: uppercase;
    background: #f1f1f1;
    padding: 10px;
  }
  .content_holder {
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
  }
`;
