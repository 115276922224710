import React from "react";
import { Box } from "@mui/material";
import { HeaderStyle } from "../styles";

const Header = () => {
  return (
    <HeaderStyle>
      <Box className="header">
        <a href="https://upload.postyourcars.com/">
          <img
            className="logo"
            src="https://i0.wp.com/upload.postyourcars.com/wp-content/uploads/2022/12/post_logo_bg_s.png?fit=1846%2C613&ssl=1"
            alt="Not-available"
          />
        </a>
        <h4 className="headingText">Customer Photo Upload Portal</h4>
      </Box>
    </HeaderStyle>
  );
};

export default Header;
