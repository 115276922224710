import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import {
  Box,
  Button,
  TextField,
  InputLabel,
  TextareaAutosize,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { Inbox, Close } from "@mui/icons-material";
import useGeoLocation from "react-ipgeolocation";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import _ from "lodash";
import axios from "axios";
import LinearLoader from "components/Loader/LinearLoader";
import "react-phone-number-input/style.css";
import SucessPopUp from "./SuccessPop";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
const thumbsContainer = {
  display: "grid",
  marginTop: 16,
  // gridTemplateColumns: "repeat(5, 1fr)",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #3e78f5",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  console.log("errors", errors);

  const onSubmit = async (data) => {
    console.log("data", data);
    if (files.length > 0 && files.length <= 20) {
      const formData = new FormData();
      formData.append("name", `${data?.firstName} ${data?.lastname}`);
      formData.append("phone", data?.phone);
      formData.append("email", data?.email);
      formData.append("description", data?.description);
      formData.append("accident", data?.accident);
      formData.append("currently_car", data?.currently_car);
      formData.append("mileage", data?.mileage);
      formData.append("zipcode", data?.zipcode);
      formData.append("vin", data?.vin);

      files?.forEach((file) => {
        formData.append("images[]", file);
      });
      setLoading(true);
      await axios
        .post("https://pmv.postyourcars.com/upload_data", formData)
        .then((res) => {
          setOpenSuccess(true);
          setLoading(false);
          setMessage("Success");
          setTimeout(() => {
            setMessage();
          }, 3000);
          reset();
          setFiles([]);
        })
        .catch((err) => {
          console.log("err", err);
          setMessage("Error");
          setLoading(false);
        });
    }
  };
  const [files, setFiles] = useState([]);
  const [dropError, setDropError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const location = useGeoLocation();

  const onDrop = (acceptedFiles) => {
    const data = [];
    if (files?.length < 20) {
      acceptedFiles?.map((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
        };
        reader.readAsArrayBuffer(file);

        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        return data.push(file);
      });

      setFiles([...files, ...data]);
    } else {
      setDropError("Only 20 files are allowed");
    }
  };

  function nameLengthValidator(file) {
    if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      return {
        code: "type-is-wrong",
        message: `Please upload vaild image`,
      };
    }
    if (+file.size > 10000000) {
      return {
        code: "Size-exceeded",
        message: `Image size must be less than 10mb`,
      };
    }
    return null;
  }

  useEffect(() => {
    if (files?.length > 0) {
      setDropError("");
    }
    if (files?.length > 20) {
      setDropError("Only 20 files are allowed");
    }
  }, [files]);

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
    validator: nameLengthValidator,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <p className="errormsg">
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </p>
    );
  });

  const remove = (file) => {
    const removeFile = _.reject(files, file);
    setFiles(removeFile);
  };

  const thumbs = () =>
    files?.map((file, i) => {
      return (
        <div key={i + 1} className="main-content">
          <div style={thumb}>
            <div style={thumbInner}>
              <img src={file.preview} style={img} alt="Not-available" />
            </div>
            <Close
              key={i + 1}
              onClick={(e) => {
                e.stopPropagation();
                remove(file);
              }}
            >
              Remove File
            </Close>
          </div>
        </div>
      );
    });

  function onErrors() {
    if (files?.length === 0) {
      setDropError("This field is required");
    } else {
      setDropError("");
    }
  }

  return (
    <Box className="formDiv">
      <h6>Please fill out the following information:</h6>
      <p className={message === "Error" ? "errormsg" : "successmsg"}>
        {message}
      </p>
      <Box
        component="form"
        className="form"
        noValidate
        onSubmit={handleSubmit(onSubmit, onErrors)}
      >
        <InputLabel>
          Name<span>*</span>
        </InputLabel>
        <Box className="d-flex">
          <Box className="form-group">
            <TextField
              className="form-control"
              error={errors.firstName !== undefined}
              helperText="First"
              {...register("firstName", { required: true })}
            />
            {errors.firstName?.type === "required" && (
              <p className="errormsg">This field is required</p>
            )}
          </Box>
          <Box className="form-group">
            <TextField
              className="form-control"
              error={errors.lastname !== undefined}
              helperText="Last"
              {...register("lastname", { required: true })}
            />
            {errors.lastname?.type === "required" && (
              <p className="errormsg">This field is required</p>
            )}
          </Box>
        </Box>
        <Box className="form-group descrip">
          <InputLabel className="">
            Email<span>*</span>
          </InputLabel>
          <TextField
            className="form-control"
            error={errors.email !== undefined}
            {...register("email", {
              pattern: /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/,
              required: true,
            })}
          />
          {errors.email ? (
            <>
              {errors.email.type === "required" && (
                <p className="errormsg">This field is required</p>
              )}
              {errors.email.type === "pattern" && (
                <p className="errormsg">Please Enter a valid Email</p>
              )}
            </>
          ) : null}
        </Box>
        <Box className="form-group">
          <InputLabel className="">
            Phone<span>*</span>
          </InputLabel>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: true,
              validate: (value) => isValidPhoneNumber(value),
            }}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                className="form-control"
                international
                countryCallingCodeEditable={false}
                countrySelectProps={{ unicodeFlags: true }}
                defaultCountry={location.country}
                placeholder="Enter phone number"
                onChange={onChange}
                value={value}
                rules={{ required: true }}
              />
            )}
          />
          {errors.phone ? (
            <>
              {errors.phone.type === "required" && (
                <p className="errormsg">This field is required</p>
              )}
              {errors.phone.type === "validate" && (
                <p className="errormsg">Please Enter a valid phone number</p>
              )}
            </>
          ) : null}
        </Box>

        <InputLabel>VIN# or Plate# </InputLabel>
        <Box className="form-group">
          <TextField className="form-control" {...register("vin")} />
          {errors.vin?.type === "required" && (
            <p className="errormsg">This field is required</p>
          )}
        </Box>
        <InputLabel>Mileage</InputLabel>
        <Box className="form-group">
          <TextField
            className="form-control"
            error={errors.mileage !== undefined}
            {...register("mileage", { required: true })}
          />
          {errors.mileage?.type === "required" && (
            <p className="errormsg">This field is required</p>
          )}
        </Box>

        <Box className="form-group">
          <InputLabel>
            Do you currently have loan or lease on this car?
          </InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            name="currently_car"
            render={({ field: { onChange, value } }) => (
              <Select
                className="form-control"
                onChange={onChange}
                value={value}
              >
                <MenuItem value="lease">Lease</MenuItem>
                <MenuItem value="finance">Finance</MenuItem>
                <MenuItem value="owned">Owned</MenuItem>
              </Select>
            )}
          />
          {errors.currently_car?.type === "required" && (
            <p className="errormsg">This field is required</p>
          )}
        </Box>

        <Box className="form-group accident">
          <InputLabel>Accident</InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            className="form-control"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <RadioGroup
                aria-label="accident"
                value={value}
                onChange={onChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            )}
            name="accident"
            control={control}
          />
          {errors.accident?.type === "required" && (
            <p className="errormsg">This field is required</p>
          )}
        </Box>

        <Box className="form-group">
          <InputLabel>ZipCode</InputLabel>
          <TextField
            className="form-control"
            error={errors.zipcode !== undefined}
            {...register("zipcode", { required: true })}
          />
          {errors.zipcode?.type === "required" && (
            <p className="errormsg">This field is required</p>
          )}
        </Box>
        <Box className="form-group">
          <InputLabel className="">Description</InputLabel>
          <TextareaAutosize
            className="form-control description"
            maxRows={6}
            placeholder={`Example: 2022 Black Mercedes S63 AMG, Like new condition. One Owner. No accident car. Premium carbon fiber upgrades. 22" aftermarket rims. Located in Irvine, CA 92614`}
            style={{ height: 120, padding: 10 }}
            {...register("description")}
          />
          <InputLabel className="upload">
            Please upload your photos here: <span>*</span>
          </InputLabel>
          <div className="container upload-cont">
            <Container
              className="upload-content"
              {...getRootProps({
                isFocused,
                isDragAccept,
                isDragReject,
                onClick: (evt) => evt.preventDefault(),
              })}
            >
              <input name="dropzone" {...getInputProps()} />
              <Inbox />
              <p>Click or drag files to this area to upload.</p>
              <em>You can upload up to 20 files.</em>
              <aside className="aside_class" style={thumbsContainer}>
                {thumbs()}
              </aside>
            </Container>
            {fileRejectionItems}
            {dropError && <p className="errormsg">{dropError}</p>}
          </div>
        </Box>
        {loading && <LinearLoader />}

        <Box className="form-group btn-cont">
          <Button type="submit">Submit</Button>
        </Box>
        <p className="acknowledgeText">
          * I acknowledge that I am the legal owner of these photos and grant
          PostYourCars all rights and uses to edit, alter, copy, exhibit,
          publish, distribute these photos for any lawful purpose.
        </p>
      </Box>
      <SucessPopUp openSuccess={openSuccess} setOpenSuccess={setOpenSuccess} />
    </Box>
  );
};

export default Form;
