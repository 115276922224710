import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const SucessPopUp = ({ openSuccess, setOpenSuccess }) => {
  return (
    <Box>
      <Dialog
        open={openSuccess}
        onClose={() => {
          setOpenSuccess(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            fontFamily: "var(--common-font)",
          }}
        >
          {"Confirm Saved"}
          <CheckCircleOutline
            fontSize="large"
            color="success"
            sx={{ marginTop: "20px", fontSize: "50px" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="#000" id="alert-dialog-description">
            Your photos have uploaded successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              margin: "0 auto 10px",
              maxWidth: "100%",
              height: "45px",
              padding: "3px 25px",
              color: "rgb(253, 249, 243)",
              fontWeight: "600",
              textTransform: "uppercase",
              background: "#061534",
              border: "none",
              borderRadius: "0px",
              outline: "0",
              cursor: "pointer",
              marginTop: "0.6rem",
              boxShadow:
                "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease-out",
            }}
            className="button-cstm"
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenSuccess(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SucessPopUp;
