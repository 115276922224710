import React from "react";
import { Box } from "@mui/material";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Form from "./Components/Form";
import { HomeStyles } from "./styles";
const Home = () => {
  return (
    <HomeStyles>
      <Box className="container-fluid">
        <Box className="row">
          <Box className="innerBox">
            <Header />
            <Form />
            <Footer />
          </Box>
        </Box>
      </Box>
    </HomeStyles>
  );
};

export default Home;
