import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AppRoutes } from "./appRoutes";

const RoutesComponent = () => {
  const location = useLocation();
  function AuthRoute({ children }) {
    if (location?.pathname === "/admin") {
      if (localStorage.getItem("isAuthenticated") === null) {
        if (location?.pathname !== "/login") {
          return <Navigate to="/login" />;
        }
      }
    }
    return children;
  }

  return (
    <Routes>
      {AppRoutes &&
        AppRoutes?.map((route, index) => {
          return (
            <Route
              key={index + 1}
              path={route?.path}
              element={<AuthRoute>{route?.component}</AuthRoute>}
            ></Route>
          );
        })}
    </Routes>
  );
};

export default RoutesComponent;
