import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { Download, Close, Preview } from "@mui/icons-material";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { DetailStyle } from "../styles";
import BackdropLoader from "components/Loader/Loader";

const DetailDialog = ({ open, handleDetailClose, detailData }) => {
  console.log('detailData',detailData)
  const [loader, setLoader] = useState(false);

  const handleDownloadAll = () => {
    setLoader(true);
    const zip = new JSZip();
    const folder = zip.folder();
    //Add images to folder
console.log('detailData?.images',detailData?.images)
    detailData?.images.forEach((photo) => {
      let r = (Math.random() + 1).toString(36).substring(7);


      const blob = fetch(photo.images, {
        headers: { "Cache-Control": "no-cache" },
      }).then((res) => res.blob());
      folder.file(`${r}_${photo.name}`, blob, { binary: true });
    });

    //Zip folder and download folderZip
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "ImagesZip");
      setLoader(false);

    });
  };

  const handlePreviewClick = async (url, fileName) => {
    const image = await fetch(url, {
      headers: { "Cache-Control": "no-cache" },
    });
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      className="main-detail"
      sx={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        background: "#fff",
      }}
    >
      <DetailStyle>
        <DialogTitle>
          Details <Close onClick={handleDetailClose} />
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box className="content-flex">
              <Box>
                <h4>Name:</h4>
                <p>{detailData?.name}</p>
              </Box>
              <Box>
                <h4>Email:</h4>
                <p>{detailData?.email}</p>
              </Box>
              <Box>
                <h4>Phone:</h4>
                <p>{detailData?.phone}</p>
              </Box>
             
              <Box>
                <h4>Vin:</h4>
                <p>{detailData?.vin ? detailData?.vin : 'N/A'}</p>
              </Box>

              <Box>
                <h4>Mileage:</h4>
                <p>{detailData?.mileage ? detailData?.mileage : 'N/A'}</p>
              </Box>

              <Box>
                <h4>Accident:</h4>
                <p>{detailData?.accident ? detailData?.accident : 'N/A'}</p>
              </Box>

              <Box>
                <h4>Zip Code:</h4>
                <p>{detailData?.zipcode ? detailData?.zipcode : 'N/A' }</p>
              </Box>

              <Box>
                <h4>Currently car on :</h4>
                <p>{detailData?.currently_car ? detailData?.currently_car : 'N/A'}</p>
              </Box>

              <Box className="description">
                <h4>Description:</h4>
                <p>{detailData?.description}</p>
              </Box>
            </Box>
            <Box className="d-flex">
              <h4>Images:</h4>{" "}
              {loader ? (
                <CircularProgress size={20} />
              ) : (
                <Button className="action_box" onClick={handleDownloadAll}>
                  Download All
                </Button>
              )}
              <Box className="data-content">
                {detailData &&
                  detailData?.images?.map((val, index) => {
                    return (
                      <Box key={index}>
                        <div className="img_holder">
                          <img src={detailData.thumbnails[index].thumbnails} alt="No-data" />
                        </div>
                        <div className="content_holder">
                          <a
                            className="preview"
                            href={val?.images}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Preview />
                          </a>

                          <Download
                            onClick={() =>
                              handlePreviewClick(val?.images, val?.name)
                            }
                          />
                        </div>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </DetailStyle>
    </Dialog>
  );
};

export default DetailDialog;
