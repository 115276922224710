import styled from "styled-components";
import background from "../../Images/bg-login.jpg";

export const LoginStyle = styled.div`
  background: url(${background}) no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 27%);
  }
  .Login_form {
    width: 100%;
    max-width: 550px;
    background: #fff;
    box-shadow: 0 0 12px rgb(0 0 0 / 10%);
    padding: 30px;
    border-radius: 10px;
    position: relative;
    img {
      display: block;
      text-align: center;
      margin: 0 auto 30px;
      max-width: 100px;
    }
  }
`;
