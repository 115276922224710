import { createGlobalStyle } from "styled-components";

export default createGlobalStyle` 
  body {
    p.errormsg {
    color: #f00;
    margin-bottom: 15px;
  }
  .aside_class{
      grid-template-columns: repeat(5, 1fr);
 @media (max-width: 991px){
  grid-template-columns: repeat(4, 1fr);
 }
  @media (max-width: 767px){
  grid-template-columns: repeat(2, 1fr)
 }
  }
  }
  .main-detail{
    max-width: 800px;
        height: 550px; 
        @media (max-width: 991px){
          max-width: 700px;
        }
        @media (max-width: 767px){
          max-width: calc(100% - 10px);
        }
  }
`;
