import React from "react";
import { Box } from "@mui/material";
import { Instagram, Facebook } from "@mui/icons-material";
import { FooterStyle } from "../styles";

const Footer = () => {
  return (
    <FooterStyle>
      <Box className="footer">
        <a
          className="postext"
          href="https://upload.postyourcars.com/"
          target="_self"
        >
          POSTYOURCARS
        </a>
        <p className="plazatext">4 Park Plaza Suite 120, Irvine CA 92614</p>
        <p className="hellotext">
          <sup>hello@postyourcars.com</sup>
        </p>
        <Box className="icons">
          <a href="https://www.instagram.com/postyourcars/">
            <Instagram />
          </a>
          <a href="https://www.facebook.com/pycinc">
            <Facebook />
          </a>
        </Box>
        <p className="proudtext">
          Proudly powered by{" "}
          <a href="https://www.postyourcars.com/" target="_self">
            PostYourCars
          </a>
        </p>
      </Box>
    </FooterStyle>
  );
};

export default Footer;
