import styled from "styled-components";

export const HeaderStyle = styled.div`
  .header {
    h4.headingText {
      font-size: 30px;
      margin: 20px 0 0;
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
    img {
      max-width: 120px;
    }
  }
`;

export const FooterStyle = styled.div`
  .footer {
    text-align: center;
    p.acknowledgeText {
      font-size: 12px;
      font-style: italic;
      color: #a7a7a7;
    }
    a.postext {
      margin: 50px auto 10px;
      display: block;
      color: #000;
      font-size: 22px;
    }
    p.plazatext {
      font-size: 18px;
    }
    p.hellotext sup {
      font-size: 14px;
    }
    .icons {
      margin: 10px auto;
      svg {
        color: #3e78f5;
      }
    }
    p.proudtext {
      a {
        color: #3e78f5;
      }
    }
  }
`;

export const FormStyles = styled.div`
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0 30px;
    font-family: var(--common-font);
  }
`;

export const HomeStyles = styled.div`
  background: #e4edf2;
  p.successmsg {
    color: green;
    margin-bottom: 15px;
  }
  p.errormsg {
    color: #f00;
    margin-bottom: 15px;
  }
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .innerBox {
    max-width: 700px;
    margin: 40px auto;
    padding: 45px;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
    border: 1px solid #e7e7e7;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    @media (max-width: 991px) {
      max-width: calc(100% - 100px);
      margin: 40px auto;
      padding: 25px;
    }
    @media (max-width: 767px) {
      max-width: calc(100% - 10px);
      padding: 15px;
    }
  }
  form.form {
    .MuiFormLabel-root {
      font-weight: 600;
      margin-bottom: 10px;
      color: #000;
      font-family: var(--common-font);
      span {
        color: #f00;
      }
      p {
        font-weight: 500;
        display: block;
        color: #000;
        font-family: var(--common-font);
        margin: 0 0 40px;
        font-size: 14px;
      }
    }
    .d-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      @media (max-width: 767px) {
        gap: 0px;
      }
      .form-group {
        max-width: calc(100% / 2 - 10px);
        width: 100%;
        @media (max-width: 767px) {
          max-width: 100%;
        }
      }
    }

    .form-group {
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
      .MuiInputBase-root {
        border: 1px solid #e4e4e4;
        background-color: #f3f4fa;
        border-radius: 0;
      }
      .form-control {
        width: 100%;
        height: 60px;
        max-width: 100%;
        border-radius: 0px;
        margin-bottom: 30px;
        font-family: var(--common-font);
        font-size: 14px;
        @media (max-width: 767px) {
          height: auto;
          margin-bottom: 0;
        }
        fieldset {
          border: none;
          top: 0;
          height: 100%;
        }
        input {
          font-family: var(--common-font);
          font-size: 14px;
        }
      }
      .form-control.PhoneInput {
        margin-bottom: 25px;
        height: 60px;
        @media (max-width: 767px) {
          margin-bottom: 0;
        }
      }
      p.errormsg {
        color: #f00;
        margin-bottom: 15px;
      }

      textarea.form-control.description {
        border: 1px solid #e4e4e4;
        background-color: #f3f4fa;
        max-width: calc(100% - 20px);
        font-family: var(--common-font);
        margin-bottom: 10px;
        outline: none;
      }
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiInputBase-colorPrimary {
      width: 100%;
    }
    .form-group.accident {
      margin-bottom: 20px;
      .MuiFormControlLabel-label {
        font-size: 14px;
      }
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    .form-group.descrip {
      .form-control {
        margin-bottom: 15px;
        font-family: var(--common-font);
        @media (max-width: 767px) {
          margin: 0;
        }
      }
      .form-group.descrip p {
        margin-bottom: 15px;
      }
    }
    .MuiFormHelperText-root {
      font-weight: 500;
      display: block;
      color: #000;
      font-family: var(--common-font);
      margin: 0 0 40px;
      font-size: 14px;
      @media (max-width: 767px) {
        margin: 0 0;
        line-height: normal;
      }
    }
  }
  .formDiv {
    h6 {
      font-size: 16px;
      font-weight: 500;
      margin: 10px 0 20px;
      font-family: var(--common-font);
    }
  }

  .form-control.PhoneInput {
    border: 1px solid #e4e4e4;
    background-color: #f3f4fa;
    justify-content: flex-end;
    input.PhoneInputInput {
      width: 100%;
      max-width: calc(100% - 60px);
      flex: calc(100% - 60px);
      min-width: auto;
      border: none;
      border-radius: 0;
      background: none;
      outline: none;
    }
  }
  .upload-content {
    p {
      margin-bottom: 10px;
      color: #000;
      @media (max-width: 767px) {
        text-align: center;
        font-size: 15px;
      }
    }
    svg {
      font-size: 44px;
      color: #3e78f5;
    }
  }
  .upload-cont {
    margin-bottom: 20px;
  }
  .form-group.btn-cont {
    margin-bottom: 20px;
    button {
      background: #061534;
      border-radius: 0;
      padding: 5px 20px;
      color: #fff;
      font-weight: 500;
      font-family: var(--common-font);
      transition: 0.3s ease all;
      border: 2px solid #061534;
      &:hover {
        background: #4688f5;
      }
    }
  }
  .main-content {
    position: relative;
    padding: 5px;
    svg {
      position: absolute;
      right: 0;
      top: -2px;
      line-height: normal;
      color: #000;
      background: #3e78f5;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: #fff;
      padding: 2px;
      cursor: pointer;
    }
  }
`;
