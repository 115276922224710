import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import DetailDialog from "./Components/DetailDialog";
import { AdminStyle } from "./styles";
import DeleteDialog from "./Components/DeleteDialog";
import BackdropLoader from "components/Loader/Loader";

const Admin = () => {
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [detailData, setdetailData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);

  const handleDetails = (e, data, images) => {
    e.stopPropagation();
    data["images"] = images;
    setdetailData(data);
    setOpen(true);
  };
  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteId(id);
    setOpenDelete(true);
  };
  async function deleteEntry() {
    await axios
      .delete(`https://pmv.postyourcars.com/upload_data/${deleteId}`)
      .then((res) => {})
      .catch((err) => {
        console.log("err", err);
      });
    setOpenDelete(false);
    getTableData();
  }

  const handleDetailClose = () => {
    setdetailData();
    setOpen(false);
  };

  const handleDeleteClose = () => {
    setDeleteId();
    setOpenDelete(false);
  };

  const columns = [
    {
      field: "idShow",
      headerName: "ID",
      sortable: false,
      flex: 1,
      headerAlign: "left",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      headerAlign: "left",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      flex: 1,
      headerAlign: "left",
      width: 200,
    },
    {
      field: "thumbnails",
      headerName: "Images",
      sortable: false,
      flex: 1,
      headerAlign: "left",
      width: 300,
      renderCell: (params) => {
        return params?.value?.map((val, index) => {
          return (
            <Box key={index} className="image_box">
              <img
                onClick={(e) => {
                  e.stopPropagation();
                }}
                width="50px"
                height="50px"
                key={index}
                src={val?.thumbnails}
                alt={index + 1}
              />
            </Box>
          );
        });
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "left",
      sortable: false,
      flex: 1,
      width: 300,
      renderCell: (params) => {
        return (
          <Box className="action_box">
            <Button
              onClick={(e) =>
                handleDetails(
                  e,
                  params?.row,
                  detailImages[`${params?.row?.id}`]
                )
              }
            >
              Details
            </Button>
            <Button
              className="delete"
              onClick={(e) => handleDelete(e, params?.row?.id)}
            >
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  let rows = [];
  let detailImages = {};
  data?.map((val, index) => {
    rows.push({
      id: val?.data?.id,
      idShow: index + 1,
      name: val?.data?.name,
      phone: val?.data?.phone,
      description: val?.data?.description,
      thumbnails: val?.thumbnails,
      email: val?.data?.email,
      currently_car: val?.data?.currently_car,
      vin: val?.data?.vin,
      accident: val?.data?.accident,
      zipcode: val?.data?.zipcode,
      mileage: val?.data?.mileage,
    });
    detailImages[`${val?.data?.id}`] = val?.images;
    return rows;
  });
  async function getTableData() {
    setLoading(true);
    setData();
    let data = await axios
      .get("https://pmv.postyourcars.com/all_upload_data")
      .then((res) => {
        setData(res?.data?.items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    return data;
  }

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <AdminStyle>
      {loading && <BackdropLoader />}
      <Box className="upload_table">
        <h1>Uploaded Data</h1>
        <DataGrid
          className="table"
          rows={rows}
          columns={columns}
          hideFooterPagination
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
        />
        <DetailDialog
          open={open}
          handleDetailClose={handleDetailClose}
          detailData={detailData}
        />
        <DeleteDialog
          openDelete={openDelete}
          handleDeleteClose={handleDeleteClose}
          deleteEntry={deleteEntry}
        />
      </Box>
    </AdminStyle>
  );
};

export default Admin;
