import { BrowserRouter } from "react-router-dom";
import GlobalCss from "./global.css";
import RoutesComponent from "./Routes/Routes";

function App() {
  return (
    <BrowserRouter>
      <GlobalCss />
      <RoutesComponent />
    </BrowserRouter>
  );
}

export default App;
